<template>
  <section>
    <br><br>
    <b-row class="text-center">
      <b-col class="col-lg-3" />
      <b-col class="col-lg-6">
        <h1>Selecione o seu Edital para Começar!</h1>
        <br>
        <span>
          <i>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </i>
        </span>
      </b-col>
      <b-col class="col-lg-3" />
    </b-row>
    <br><br><br>
    <b-row class="text-center">
      <b-col class="col-lg-4">
        <card-background titulo="Edital para Concurso">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. olor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua <hr>
          <b-button
            block
            variant="primary"
            to="/pages/edital/novo/inserir/0/tipo/1"
          >
            Começar
          </b-button>
        </card-background>
      </b-col>
      <b-col class="col-lg-4">
        <card-background titulo="Edital para OAB">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. olor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua <hr>
          <b-button
            block
            variant="primary"
            to="/pages/edital/novo/inserir/0/tipo/2"
          >
            Começar
          </b-button>
        </card-background>
      </b-col>
      <b-col class="col-lg-4">
        <card-background titulo="Edital para ENEM">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. olor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua <hr>
          <b-button
            block
            variant="primary"
            to="/pages/edital/novo/inserir/0/tipo/3"
          >
            Começar
          </b-button>
        </card-background>
      </b-col>
    </b-row>
    <div style="height: 1000px" />
    <b-row>
      <b-col>
        <ciclo-estudos />
      </b-col>
      <b-col class="col-lg-3">
        <cronometro-ciclo />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BButton, BFormInput,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import CronometroCiclo from '@/views/components/ciclo/CronometroCiclo.vue'
import CicloEstudos from '@/views/components/ciclo/CicloEstudos.vue'
import CardBackground from '../../components/card/CardBackground.vue'

export default {
  name: 'IndexInserir',
  components: {
    CicloEstudos,
    BRow,
    BCol,
    BButton,
    CronometroCiclo,
    CardBackground,
    BFormInput,
  },
  data() {
    return {
      doZero: '',
    }
  },
  created() {
    const userData = getUserData()
    this.$http
      .get('ciclos', { id: userData.id })
      .then(response => {
        response.data.forEach(json => {
          this.ciclos.push(json)
        })
      })
      .catch(response => {
        console.log(response)
      })
  },
  methods: {
    iniciarDoZero() {
      const userData = getUserData()
      this.$http.post('ciclos/store', { nome: this.doZero })
        .then(response => {
          console.log(response)
          if (response.data > 0) this.$router.push({ path: `/pages/ciclo/doZero/${response.data}` })
          else alert('Erro ao criar')
        }).catch(response => {
          console.log(response)
        })
    },
    iniciarPorConcurso(){
      this.$router.push({ path: `/pages/ciclo/porConcurso` })
    }
  },
}
</script>
